.container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  color: #000;
  z-index: 2;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 1rem 1rem 0 0;
  overflow-y: hidden;
  overflow-x: hidden;
}

.popupContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-around; */
  justify-content: end;
  /* height: 40vh; */
  overflow-y: hidden;
  overflow-x: hidden;
}

.controlButtonArea {
  display: flex;
  position: relative;
}
.controlButtonArea .closeButton {
  top: 0;
  right: 0;
  position: absolute;
  gap: 1rem;
  background-color: transparent;
  border: none;
  margin: 0.75rem;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  /* gap: 1rem; */
  position: relative;
  height: 20rem;
  width: 100%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
}

.popupContent img {
  cursor: pointer;
  border-radius: 0.5rem;
  height: 4rem;
}
.skillContainer {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  height: 100%;
}

.backdrop {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 1rem;
  padding: 1rem;
  position: relative;
  width: 100%;
  cursor: pointer;
}

.itemImage {
  cursor: pointer;
  border-radius: 0.5rem;
  height: 4rem;
}

/* .backdrop .item:last-child {
  padding-bottom: 0;
} */

.bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
}

.itemContent {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 0.5rem;
}
.itemContent h3 {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 600;
  padding: 0;
  margin: 0;
  color: #fff;
}
.itemContent p {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  padding: 0;
  margin: 0;
  color: #fff;
}

.selected {
  border: 2px solid #fff;
  background-color: #fff;
}

.actionButton {
  display: flex;
  height: 3.5rem;
  width: 100%;
  justify-content: center;
}

.actionButton button {
  width: 100%;
  background-color: rgba(34, 80, 145, 0.83);
  border: none;
}
