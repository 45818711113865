.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.inventoryItem {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  border: 1px solid #fff;
  border-radius: 0.75rem;
  grid-template-rows: 4rem;
  gap: 0.75rem;
  height: 100%;
  margin-top: 0.75rem;
  padding: 0.75rem;
  background-color: #0e2f4f;
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
  border-radius: 20px 20px 0px 0px;
  background-color: #0e2f4f;
  color: #fff;
  padding: 0.75rem;
}
