/* Slider.module.css */

* {
  margin: 0;
  box-sizing: border-box;
}

.sliderWrapper {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.slider {
  display: flex;
  transition: transform 0.5s ease;
  height: 100%;
}

.item {
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  width: 100%;
  height: auto;
}

.prev,
.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  border: none;
  font-size: 24px;
  padding: 10px;
  cursor: pointer;
  z-index: 10;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}
