.heroTop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.hero {
    display: flex;
    flex-direction: row;
    /* gap: 1rem; */
    justify-content: space-between;
    /* max-width: 50%; */
    width: 100%;
}

.heroLeft {
    padding-right: 5px;
}

.heroRight {
    padding-left: 5px;
}

.heroImage {
    width: 35px;
    height: 35px;
    border-radius: 1rem;
}

.heroInfo p {
    white-space: nowrap;
    /* Prevents text from wrapping to the next line */
    overflow: hidden;
    /* Hides any overflowed content */
    text-overflow: ellipsis;
    /* Adds "..." at the end of the truncated text */
    max-width: calc(50vw - 40px - 2rem);
}

.heroRank {
    display: flex;
    flex-direction: row;
    gap: 2px;
}

.heroRank img {
    width: 20px;
    height: 20px;
}

.heroLeft .heroInfo .heroRank {
    float: right;
}


.heroTopContainer {
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: calc(50% - 20px);
}