/* Container */
.container {
  display: flex;
  flex-direction: column;
  align-items: start;
}

/* Title */
.title {
  font-size: 1.25rem;
  font-weight: 600;
  width: 100%;
}

.tabButton {
  display: flex;
  /* align-items: center; */
  gap: 12px;
  margin-top: 16px;
}

.button {
  background-color: transparent;
  color: #3182ce;
  border: 1px solid #3182ce;
  border-radius: 9999px;
  padding: 8px 16px;
  font-weight: 600;
  cursor: pointer;
}

.active {
  background-color: #3182ce;
  color: #fff;
}

.mission {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
  margin-top: 24px;
}

.title {
  font-size: 1.25rem;
  font-weight: 600;
}

.description {
  font-size: 1rem;
  color: #4a5568;
  margin-top: 6px;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
  border: 1px solid #4a5568;
  border-radius: 12px;
  padding: 12px;
  cursor: pointer;
}

.missionIcon {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.missionIcon img {
  width: 24px;
  height: 24px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;
  width: 100%;
  padding: 12px;
}

.content p {
  display: flex;
  padding: 0;
  margin: 0;
  color: #fff;
}

.progress {
  display: flex;
  align-items: center;
  justify-content: center;
}
