.upgradeItem {
  position: relative;
  width: 100%;
}

.upgradeButton {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px;
  width: 100%;
  background-color: #f3f4f6; /* Light neutral color */
  border: 1px solid #4f6d7a; /* Light primary color */
  border-radius: 20px;
  position: relative;
  z-index: 1;
  cursor: pointer;
  gap: 16px;
}

.upgradeImage {
  width: 56px;
  height: 56px;
  object-fit: contain;
}

.upgradeInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.upgradeTitle {
  font-size: 1.1rem;
  font-weight: 600;
  color: #2d3748; /* Dark neutral */
}

.upgradeDescription {
  font-size: 0.9rem;
  color: #4a5568; /* Medium neutral */
  margin-top: 4px;
}

.costInfo {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
}

.tokenIcon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.cost {
  font-size: 1rem;
  color: #2d3748; /* Dark neutral */
}
