.bonusContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  margin: 0;
}

.dailyItem {
  position: relative;
  width: 100%;
}

.dailyButton {
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 10px;
  width: 100%;
  background-color: #f3f4f6;
  /* Light neutral color */
  border: 1px solid #4f6d7a;
  /* Light primary color */
  border-radius: 20px;
  position: relative;
  z-index: 1;
  cursor: pointer;
  gap: 16px;
}

.dailyCheckinImage {
  width: 56px;
  height: 56px;
  object-fit: contain;
}

.dailyInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.dailyTitle {
  font-size: 1.1rem;
  font-weight: 600;
  color: #2d3748;
  /* Dark neutral */
}