.container {
  display: flex;
  flex-direction: column;
  /* padding: 20px; */
  margin: 0;
  padding: 0 !important;
  width: 100%;
  /* min-height: calc(100vh - 40px); */
  height: 100%;
  background-size: cover;
  position: relative;
  /* justify-content: space-between; */
  justify-content: start;
}

/* Custom Styles for the ToastContainer */
.toastContainer {
  margin-top: 16px;
  /* Give space between toasts */
  padding: 0;
  /* Remove default padding */
  max-width: 320px;
  /* Limit the width of the container */
}

/* Custom Styles for Each Toast Item */
.toastItem {
  font-size: 14px;
  /* Smaller text */
  font-weight: 500;
  /* Slightly bolder text */
  padding: 8px 16px;
  /* Reduced padding */
  border-radius: 8px;
  /* Rounded corners */
  background-color: #333;
  /* Dark background */
  color: #fff;
  /* White text */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  /* Slight shadow for depth */
  transition: background-color 0.3s ease;
  /* Smooth transition for hover effect */
}

/* Optionally, you can add a hover effect */
.toastItem:hover {
  background-color: #444;
  /* Slightly lighter background on hover */
}