.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  padding: 1rem;
  justify-content: space-between;
  width: 100%;
  overflow-y: auto;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  padding: 1rem;
  position: relative;
  height: 14rem;
}

.content .backgroundImage {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 0.5rem;
}

.item {
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  position: relative;
  height: 14rem;
}

.itemImage {
  height: 7rem;
  width: 7rem;
}

.itemName {
  /* font-size: 1.125rem; */
  line-height: 1.75rem;
  font-weight: 600;
  text-shadow: 0px 2px 0 rgba(0, 0, 0, 0.6);
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.buyButton {
  background-color: #3b82f6;
  color: #fff;
  border: none;
  border-radius: 9999px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.buyButton img {
  height: 20px;
  width: 20px;
}