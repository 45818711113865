.container {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
    width: 100%;
    /* height: 100%; */
    gap: 1rem;
    color: #fff;
}

.headerLeft {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 0.25rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
}

.headerLeft img {
    width: 24px;
    height: 24px;
}

.house {
    display: flex;
    flex-direction: row;
    gap: .75rem;
}

.hero {
    display: flex;
    flex-direction: row;
    border: none;
    border-radius: .75rem;
    padding: .5rem;
    background-image: linear-gradient(180deg, #009aff 25%, var(--primary-color));
}

.hero img {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
}

.season {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: .5rem;
    gap: 0.25rem;
    font-weight: 600;
    font-size: .875rem;
    line-height: 1.25rem;
    border: 1px solid;
    border-color: rgb(65 165 239 / 1);
    background-color: var(--primary-color);
    border-radius: .75rem;
    cursor: pointer;
}

.season img {
    width: 1.5rem;
    height: 1.5rem;
}

.champion {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
}

.championImage {
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.championImage img {
    width: 34px;
    height: 34px;
}

.championInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    border: none;
    background-color: rgb(38 149 231 / 1);
    padding: .5rem .5rem .5rem 1rem;
    border-radius: 0rem .5rem .5rem 0rem;
    margin-left: -1rem;

}


.championInfo p {
    font-size: 0.75rem;
}

.championInfo img {
    width: 16px;
    height: 16px;
}

.headerRight {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: center;
    gap: 0.25rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
}

.enegy {
    display: flex;
    flex-direction: row;
    gap: .25rem;
    font-weight: 600;
    font-size: .875rem;
    line-height: 1.25rem;
    align-items: center;
}

.enegy img {
    height: 14px;
    width: 14px;
}

.counter {
    font-size: .625rem;
    line-height: .75rem;
    font-weight: 400;
}