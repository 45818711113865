.container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    min-height: 100%;
    width: 100%;
    justify-content: space-between;
}

.mainContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
}

.flex {
    display: flex;
    flex-direction: column;
    border: 1px solid #fff;
    border-radius: 0.75rem;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: #fff;
}

.reward {
    display: grid;
    grid-template-columns: 40px 1fr 20px;
    position: relative;
    padding: 1rem;
}

.rewardImage {
    display: flex;
    align-items: center;
    justify-content: center;

}

.reward .gift {
    height: 34px;
    width: 34px;
}

.absolute {
    position: absolute;
    right: 5px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.absolute img {
    width: 20px;
    height: 20px;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    position: relative;
}

.content p {
    padding: 0;
    margin: 0;
    font-size: .75rem;
    line-height: 1rem;
    text-align: left;
}

.description {
    font-size: .625rem;
    line-height: .75rem;
    font-weight: 400;
}

.referral {
    border: none;
    flex-direction: row;
    justify-content: space-between;
}

.userReferral {
    display: flex;
    border: none;
    flex-direction: row;
    margin: 10px;
    justify-content: start;
}

.userImage img {
    height: 40px;
    width: 40px;
}

.userInfo {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    margin-left: 1rem;
    gap: .25rem;
}

.userInfo p {
    display: flex;
    padding: 0;
    margin: 0;
    font-weight: 600;
    font-size: 20px;
}

.userDetail p {
    color: rgb(236 236 237);
    font-weight: 300;
    font-size: .875rem;
    line-height: 1.25rem;
}

.userDetail img {
    height: 20px;
    width: 20px;

}

.bottom {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    margin-bottom: 1rem;
}

.bottomContainer {
    border: none;
    border-radius: .75rem;
    /* background: linear-gradient(to bottom,
            #4a90e2,
            #004e92); */

    background: linear-gradient(180deg, #41a5ef, #2695e7 51.86%, #0c6dbd 108.04%);
    display: flex;
    justify-content: center;

}

.referralContainer {
    width: 70%;
}

.copyContainer {
    width: 20%;
}

.button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px;
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 600;

}

.scroll {
    max-height: calc(100vh - 495px);
    overflow-y: auto;
}