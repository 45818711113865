.container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: space-between;
}

.characterContainer {
  height: calc(100% - 412px);
  display: flex;
  flex-direction: column;
}

.checkNewsButton {
  background-color: #2a79ff;
  color: var(--primary-text-color);
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 1.2em;
  cursor: pointer;
  margin-top: 20px;
}

.absolute {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  top: 37vh;
  z-index: 1;
}

.icon {
  width: 60px;
  height: 60px;
}
