.container {
    display: flex;
    flex-direction: column;
    gap: 3;
    justify-content: space-between;
    width: 100%;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.item {
    display: flex;
    flex-direction: column;
    border: 1px solid #fff;
    border-radius: 0.75rem;
    grid-template-rows: 55px;
    gap: 0.75rem;
    height: 100%;
    margin-top: 0.75rem;
    padding: 0.75rem;
    background-color: #0e2f4f;

    cursor: pointer;
}

.title {
    font-size: 1.1rem;
    font-weight: 600;
    color: #fff;
}

.description {
    font-size: .75rem;
    color: #fff;
}

.active {
    background-color: #3182ce;
    color: #fff;
    cursor: unset;
}