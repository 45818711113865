.container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1.5rem;
  color: #000;
  z-index: 2;
  height: 100%;
  overflow: hidden;
}

.bgPopup {
  background-color: rgba(31, 69, 87, 1);
}
