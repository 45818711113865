.claimContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.claimButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  border-radius: 12px;
  padding: 16px;
  width: 300px;
  border: 1px rgb(32 124 168 / 83%) solid;
  border-radius: 1rem;
}

.tokenInfo {
  display: flex;
  align-items: center;
  gap: 8px;
}

.icon {
  width: 24px;
  height: 24px;
}

.tokenValue {
  font-size: 1.2em;
  font-weight: bold;
}

.tokenRate {
  font-size: 0.8em;
  margin-left: 0.5rem;
  color: #d1d5db;
  /* Lighter text color */
}

.claimButtonText {
  font-size: 1.2em;
  font-weight: bold;
  background-color: var(--primary-color);
  color: var(--primary-text-color);
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.claimButtonText:hover {
  background-color: #357ebd;
}