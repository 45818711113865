tbody {
  display: block;
  /* max-height: calc(100% - 47px); */
  overflow: auto;
  max-height: 100%;
}
.tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.th,
.td {
  padding: 10px 15px;
  text-align: left;
}
thead {
  width: calc(100% - 1em);
}

.table {
  height: 100%;
}
