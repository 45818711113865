.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  justify-content: space-between;
}

.characterContainer {
  height: calc(100% - 450px);
  width: 100%;
}

.attackButton {
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 1rem;
}

.absolute {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  top: 37vh;
  z-index: 1;
}

.inventoryButton {
  width: 60px;
  height: 60px;
  background-image: linear-gradient(180deg, #009aff 25%, #015f9c);
  border: #fff 1px solid;
  border-radius: 15px;
  color: #fff;
  font-weight: 700;
  font-size: 0.625rem;
  line-height: 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}

.inventoryButton img {
  width: 40px;
  height: 40px;
}

.inventoryButton span {
  position: absolute;
  top: 2.3rem;
}