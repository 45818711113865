.container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: start;
  overflow: hidden;
  width: 100%;
}

.character {
  display: flex;
  flex-direction: column;

  height: 45vh;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 0.25rem;
  overflow-y: auto;
  max-height: calc(100vh - 33vh - 215px);
}

.section {
  padding: 0.5rem;
  align-items: center;
  margin: 0 1rem;
  justify-content: space-between;
}

.profile {
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  border: 1px solid #fff;
  border-radius: 0.5rem;
}

.profile div {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.75rem;
}

.profile div img {
  width: 24px;
  height: 24px;
}

.rate {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.rate div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
}

.rate div p {
  font-size: 0.75rem;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rate p img {
  width: 16px;
  height: 16px;
}

.characterInfo {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.gridItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  border-bottom: 1px solid #ccc;
  height: 100%;
  padding-top: 1.5rem;
}

.gridItem p {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
}

.gridItem .statsValue {
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: center;
  gap: 0.5rem;
}

.statNameLeft {
  display: flex;
}

.statNameRight {
  padding-left: 5px;
}

.statValueLeft {
  padding-right: 5px;
}

.statValueRight {
  display: flex;
}

.skill {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.skill div {
  /* flex: calc(50% - 2.5rem); */
  width: calc(50% - 2.5rem);
  padding: 5px 0;
}

.skill div:last-child {
  border-bottom: none;
}

.skillItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  padding-top: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.skillItem .skillInfo {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 0.25rem;
  padding: 0.5rem;
  border: 1px solid rgb(38 149 231 / 1);
  border-radius: 0.75rem;
  width: 100%;
}

.skillItem .skillImage {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  height: 1.5rem;
  width: 1.5rem;
  border: 1px solid rgb(38 149 231 / 1);
  border-radius: 0.75rem;
  padding: 0.5rem;
  background-color: rgb(17 63 105 / 1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.skillName {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  align-items: center;
}

.skillBuffer {
  display: flex;
  flex-direction: column;
  width: 100% !important;
}

.buffer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  border-bottom: 1px solid #ccc;
  width: 100% !important;
}

.buffer p {
  font-size: 0.7rem;
  line-height: 1rem;
}