.imageContainer {
  position: relative;
  display: inline-block;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.image {
  width: 100%;
  height: auto;
  transition: filter 1s ease-out;
}

.grayscaleMask {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  background-color: white;
  z-index: 2;
  background: linear-gradient(to left, #000000, transparent);
  background: linear-gradient(to right, transparent 60%, black 100%);
  filter: grayscale(1);
  transition: width 0.3s cubic-bezier(0.49, 0.28, 0.38, 0.18);
}

.loadingOverlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: white;
  z-index: 3;
}


.mainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}