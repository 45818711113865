.progress {
  display: flex;
  width: 100%;
  height: 10px;
  background: #ddd;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  justify-content: space-between;
  text-align: center;
}

.progress .step {
  flex-grow: 1;
}

/* 
.progress .step:last-child {
  border-right: 1px solid #fff rgba(0, 0, 0, 0.8);
} */

.progress .step.complete {
  background: #3d9970;
}