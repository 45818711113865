.popup {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 1rem 1rem 0 0;
}

.popupHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 2rem 0;
    color: #fff;
}

.popupHeader h2 {
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.popupHeader p {
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
}

.popupClose {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0.75rem;
}

.popupClose button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    border: none;
    background: transparent;
    color: #fff;
}

.popupContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    max-width: 343px;
    margin: 0 auto;
    width: 100%;
}

.upgradeButton {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0);
    border-radius: .75rem;
    width: 100%;
    height: 100%;
    margin: 2rem 0;
}

.upgradeButton button {
    font-size: 1.25rem;
    line-height: 1.75rem;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .15s;
    color: rgb(255 255 255);
    background-color: #0064BC;
    height: 4rem;
    width: 100%;
    border-radius: 0.75rem;
    border: none
}