.container {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  min-height: 100%;
  width: 100%;
  justify-content: space-between;
}

.absolute {
  position: absolute;
  width: calc(100vw - 4rem);
  height: 45vh;
  top: 0;
  display: flex;
}

.round {
  position: absolute;
  display: flex;
  color: #fff;
  font-weight: 700;
}

.heroTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.hero {
  display: flex;
  flex-direction: row;
  /* gap: 1rem; */
  justify-content: space-between;
  /* max-width: 50%; */
  width: 100%;
}

.heroLeft {
  padding-right: 5px;
}

.heroRight {
  padding-left: 5px;
}

.heroImage {
  width: 35px;
  height: 35px;
  border-radius: 1rem;
}

.heroInfo p {
  white-space: nowrap;
  /* Prevents text from wrapping to the next line */
  overflow: hidden;
  /* Hides any overflowed content */
  text-overflow: ellipsis;
  /* Adds "..." at the end of the truncated text */
  max-width: calc(50vw - 40px - 2rem);
}

.heroRank {
  display: flex;
  flex-direction: row;
  gap: 2px;
}

.heroRank img {
  width: 20px;
  height: 20px;
}

.heroLeft .heroInfo .heroRank {
  float: right;
}

.main {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;
}

.mainAction {
  top: 1rem;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: space-between;
  width: 100%;
}

.mainCharacters {
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  /* justify-content: space-between; */
  /* height: 10.7rem; */
  /* height: calc(100vh - 372px); */
  height: calc(100vh - 540px);
  width: 100%;
  justify-content: center;
  position: relative;
}

.character {
  display: flex;
  min-width: calc(50% - 1rem);
}

.character::before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  height: 100%;
  width: 50%;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255);
  background: radial-gradient(#2e90fa, #1570ef) no-repeat;
  background-size: 50% 60%;
  background-position: 30% 75%;
  filter: blur(25px) opacity(0.3);
  content: "";
}

.character::after {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  height: 100%;
  width: 50%;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 /1);
  background: radial-gradient(#b6271f, #bc4c46) no-repeat;
  background-size: 60% 70%;
  background-position: 75% 90%;
  filter: blur(25px) opacity(0.3);
  content: "";
}

.actionContent {
  padding: 0rem 1rem;
}

.button {
  background-color: transparent;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  padding: 0.25rem;
  cursor: pointer;
}

.tabs {
  display: flex;
}

.stats {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding: 0.25rem 0;
}

.statsItemLine {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.statsCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.statsLeft {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 2rem;
}

.statsRight {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 2rem;
}

.skill {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
}

.skill img {
  width: 20px;
  height: 20px;
  object-fit: cover;
}

.action {
  display: flex;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
  gap: 2rem;
  height: 40px;
}

.btn {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 50%;
  height: 40px;
  background-color: #2e90fa;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  color: #fff;
}

button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  font: inherit;
  color: inherit;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
}

.shareButton {
  display: flex;
  flex-direction: column;
}

.nextButton {
  display: flex;
  flex-direction: column;
}

.mainCharacters canvas {
  position: absolute;
}

.mainCharacters canvas:last-child {
  z-index: 10;
}


.stage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.heroTopContainer {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: calc(50% - 20px);
}