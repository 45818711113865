/* Container */
.container {
  position: relative;
  overflow-y: auto;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  /* margin-top: 20px;
  margin-bottom: 30px; */
}

.token {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.balanceText {
  font-size: 1.25rem;
  font-weight: bold;
}
