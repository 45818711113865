.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  /* height: 33vh; */
}

.item {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  justify-content: space-between;
  gap: 2.5rem;
  position: absolute;
  top: 0;
  border: 0;
  width: 100%;
  z-index: 1;
}

.item button {
  justify-self: center;
}

.character {
  width: calc(100% - 190px);
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
}
