.container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  color: #000;
  z-index: 2;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 1rem 1rem 0 0;
  overflow-y: hidden;
  overflow-x: hidden;
}

.popupContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
  overflow-x: hidden;
  padding: 1rem;
  gap: 2rem;
}

.header {
  display: flex;
  align-items: start;
  flex-direction: row;
  justify-content: start;
  gap: 1rem;
  width: 100%;
  padding: 1rem 0;
  color: #fff;
}

.token {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.token p {
  border: 1px solid rgb(38 149 231 / 1);
  padding: 0.5rem;
  padding-left: 2.5rem;
  border-radius: 1.5rem;
  background-color: rgb(13 74 127 / 1);
}

.token img {
  cursor: pointer;
  border-radius: 0.5rem;
  height: 2.5rem;
  width: 2.5rem;
  position: absolute;
  left: 0.5rem;
}

.controlButtonArea {
  display: flex;
  position: relative;
}

.controlButtonArea .closeButton {
  top: 0;
  right: 0;
  position: absolute;
  gap: 1rem;
  background-color: transparent;
  border: none;
  margin: 0.75rem;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  /* gap: 1rem; */
  position: relative;
  height: 20rem;
  width: 100%;
  border: 1px solid rgb(38 149 231 / 1);
  background-color: #008bc933;
  border-radius: 0.5rem;
  color: #fff;
  overflow-y: auto;
  overflow-x: hidden;
  gap: 1rem;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.image .itemImage {
  cursor: pointer;
  border-radius: 0.5rem;
  height: 7rem;
  width: 7rem;
}

.itemLevel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
}

.itemLevel img {
  cursor: pointer;
  border-radius: 0.5rem;
  height: 2rem;
  width: 2rem;
}

.itemStats {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 0.5rem;
  width: 100%;
}

.itemStats p {
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0 1rem;
  margin: 0;
  color: #fff;
  font-weight: 700;
}

.detail {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 1rem;
  width: calc(100% - 3rem);
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  margin: 0.5rem;
  padding: 1rem;
  background-color: rgb(11 39 70);
}

.info h3 {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 1rem;
  padding: 1rem;
  position: relative;
  cursor: pointer;
}

.itemImage {
  cursor: pointer;
  border-radius: 0.5rem;
  height: 4rem;
}

/* .backdrop .item:last-child {
  padding-bottom: 0;
} */

.bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
}

.itemContent {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 0.5rem;
}

.itemContent h3 {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 600;
  padding: 0;
  margin: 0;
  color: #fff;
}

.itemContent p {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  padding: 0;
  margin: 0;
  color: #fff;
}

.selected {
  border: 2px solid #fff;
  background-color: #fff;
}

.actionButton {
  display: flex;
  height: 3.5rem;
  width: 100%;
  justify-content: center;
}

.actionButton button {
  width: 100%;
  background-color: #2697e7b1;
  border: none;
  border-radius: 1rem;
  color: #fff;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 600;
  width: 100%;
  background-color: rgba(34, 80, 145, 0.83);
  border: none;
}

.rollButton {
  width: 12rem !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
}

.rollButton .tokenIcon {
  width: 20px;
  height: 20px;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.hint {
  padding-bottom: 1rem;
}

.hint p {
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
  margin-top: 0.5rem;
}

.level1 {
  color: white !important;
}

.level2 {
  color: green !important;
}

.level3 {
  color: blue !important;
}

.level4 {
  color: purple !important;
}

.level5 {
  color: orange !important;
}
