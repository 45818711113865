.container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  height: 100%;
  justify-content: space-between;
}

.notice {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 0.5rem;
}
.notice p {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  height: 30vh;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 1rem;
  height: calc(100vh - 30vh - 170px);
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
