.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;

  /* padding-bottom: 0.125rem; */
  background-color: rgb(17 63 105);
  border: none;
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 1rem;
  border: 1px solid #2388e7;
  padding: 2rem;
}

.button img {
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 1rem;
}

.emptyImage {
  width: 2rem !important;
  height: 2rem !important;
}

.SWORD {
  height: unset !important;
  rotate: -45deg;
}
